import React, { Fragment } from "react";

import Intro from "../components/Intro";
import Services from "../components/services/Services";
const Home = () => (
  <Fragment>
    <Intro />
    <hr></hr>
    <Services />
  </Fragment>
);

export default Home;
