// Helper function for handling image scaling needed for SAM
const handleImageScale = image => {
    // Input images to SAM must be resized so the longest side is 1024
    const LONG_SIDE_LENGTH = 1024
    let w = image.naturalWidth
    let h = image.naturalHeight
    const samScale = LONG_SIDE_LENGTH / Math.max(h, w)
    return { height: h, width: w, samScale }
  }
  
  const handleScale = (w, h) => {
    // Input images to SAM must be resized so the longest side is 1024
    const LONG_SIDE_LENGTH = 1024
  
    const samScale = LONG_SIDE_LENGTH / Math.max(h, w)
    return { height: h, width: w, samScale }
  }
  
  export { handleImageScale, handleScale }
  