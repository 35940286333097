import React from "react";

import logo from "../assets/logo.png";
import "./Intro.css"
const Intro = () => (
  <div className="text-center hero my-5">
    <div className="logo-container">
      <div className="logo-img">
        <img className="mb-3 app-logo" src={logo} alt="React logo" width="50" height="50" />
      </div>
      <div className="logo-text">
        <p> MLDesk</p>
      </div>
    </div>
    
    <h1 className="mb-4">Rapid Image Annotation</h1>
    <p className="lead">
      Prompt labeling and inference powered by foundation models for rapid image annotation.
    </p>
    
  </div>
);

export default Intro;
