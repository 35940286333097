import React,  { useState } from "react";

import Box from "@mui/material/Box";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

import TextAndVideoComponent from "./textAndVideo";
import "./Services.css";
import videoFile from '../../assets/videos/annotation_homepage_demo.mp4';
import finetuneImg from '../../assets/finetuning_prototype.png'
import inferImg from '../../assets/inference_prototype.png'

const Services  = () => {
    const [value, setValue] = React.useState("1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const CustomTab = styled((props) => <Tab disableRipple {...props} />)(
        ({ theme }) => ({
          textTransform: "none"
        })
    );
    return (
        <div>
            <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                    <Box sx={{ width: "100%" }}>
                        <TabList
                        onChange={handleChange}
                        aria-label="tabs example">
                            <CustomTab className="custom-tab" label="Annotation" value="1"/>
                            <CustomTab className="custom-tab" label="Finetuning" value="2" />
                            <CustomTab className="custom-tab" label="Inference" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <TextAndVideoComponent 
                            videoFile={videoFile}
                            header="Annotation"
                            textContent={
                                [
                                "Effortlessly generate segment polygons by clicking in the area of interest. This process is powered by Segment Anything Model",
                                "Refine and perfect your segments with our powerful editing capabilities.",
                                "Generate bounding box outputs using pretrained models such as Yolo, Detectron by selecting area of interest. ",
                                "Experience seamless segmentation with MLDesk, where automation meets precision."
                                ]
                            }
                         />
                    </TabPanel>
                    <TabPanel value="2">
                        <TextAndVideoComponent 
                            imgFile={finetuneImg}
                            header="Fintetuning"
                            textContent={[
                            "Finetune our foundation model on your data. ",
                            "The supported models are SAM Decoder, Yolo, Clip, Dino, Detectron, etc. ",
                            "All supported models can be finetuned with little data and short training time, yet can achieve high accuracy"
                            ]}
                         />
                    </TabPanel>
                    <TabPanel value="3">
                        <TextAndVideoComponent 
                            imgFile={inferImg}
                            header="Inference"
                            textContent={[
                            "Infer at scale with our batch inference capabilities which can process thousands of images in minutes. ",
                            "Perform batch inference on models fine-tuned on your data or pretrained models such as Yolo, Dino, Clip, Detectron, etc. ",
                            "Refine and perfect outputs using editing capabilities on our annotation platform. ",
                            "Perform data exploration and analytics on the inference outputs. "
                            ]}
                         />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
};

export default Services;