import React from "react";
import {useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  const paths_with_footer = ["/"]
  const isFooterNeeded = paths_with_footer.includes(pathname)
  return (
    isFooterNeeded ? (
      <footer className="bg-light p-3 text-center">
        {/* <div className="logo" /> */}
        <p>
        {/* Sample project provided by <a href="https://auth0.com">Auth0</a> */}
        </p>
      </footer>
    ) : <></>
    
  );
  
};


export default Footer;
