import React from 'react'
import './projectCard.css'

      
const ProjectCard = (props) => {
    return (
        <>
            <div className="ProjectCard">
                <img src={props.image} alt="No img"/>
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </>
    )
}

export default ProjectCard