import React from 'react';
import './textAndVideo.css'; // Import the CSS file

const TextAndVideoComponent = (props) => {

  let servicesList = [];

  props.textContent.forEach((serv, index) => {
    servicesList.push(<li key={index}>{serv}</li>);
  });

  return (
    <div className="text-and-video-container"> {/* Add className here */}
      <div className="text-content">
        <h4 className='text-content-h4'>{props.header}</h4>
        <ul className='text-content-p'>{servicesList}</ul>
      </div>
      {
        (props.videoFile) ?
        <video className="video-content-2" autoPlay loop muted>
          <source src={props.videoFile} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        :
        <img className="video-content-2" src={props.imgFile}></img>
      }
    </div>
  );
};

export default TextAndVideoComponent;